import React, { useEffect,useLayoutEffect,useState, useMemo, Suspense } from "react";
import "./App.css";
import {
  ADMIN_APP,
  DASHBOARD_APP,
  PATIENT_SCREEN,
  LOGIN_SCREEN,
  DASHBOARD_SCREEN,
  HOSPITAL_SCREEN,
  PagesList,
  allRoutes
} from "./AppConstants";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import LoginScreen from "./components/admin/screens/LoginScreen";
import API from "./api";
import SystemAlertScreen from "./components/admin/screens/SystemAlertScreen";
import CustomAlert from "./components/common/Alert";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PolicyIcon from '@material-ui/icons/Policy';
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Typography from "@material-ui/core/Typography";
import ChatIcon from '@material-ui/icons/Chat';
import NavigationComponent from "./components/common/NavigationComponent";
import ChangePasswordScreen from "./components/common/ChangePassword";
import SuccessScreen from "./components/common/successScreen";
import Link from '@material-ui/core/Link';
import ForgotPasswordScreen from "./components/admin/screens/ForgotPassword";
import ErrorScreen from "./components/common/ErrorComponent";
/* import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics"
import "firebase/firestore"
import axios from "axios"
import "firebase/functions"; */

/* const firebaseConfigs = {
  apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
  authDomain: "dev-omnicure.firebaseapp.com",
  databaseURL: "https://dev-omnicure.firebaseio.com",
  projectId: "dev-omnicure",
  storageBucket: "dev-omnicure.appspot.com",
  messagingSenderId: "462063129427",
  appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
  measurementId: "G-7CMWQ1D0HX"
};
  // Initialize Firebase
firebase.initializeApp(firebaseConfigs);
firebase.analytics(); */

const AdminApp = React.lazy(() =>
  import(
    /* webpackChunkName: "AdminApp",webpackPreload: true */ "./AdminExports"
  )
);
const DashboardApp = React.lazy(() =>
  import(/* webpackChunkName: "DashboardApp"*/ "./DashBoardExports")
);
const TriageApp = React.lazy(() =>
  import(/* webpackChunkName: "TriageApp"*/ "./TriageExports")
);
const ResponsiveDrawer = React.lazy(() =>
  import(/* webpackChunkName: "DrawerToolbar"*/ "./components/common/SideBar")
);
export const PrivateRoute = ({
  children,
  isCurrentApp,
  userId,
  component,
  location,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={
        userId != null && isCurrentApp
          ? component
          : () => (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
};

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ padding: "3px" }}
    >
      {"Copyright © "}
      <span color="inherit">{`${new Date().getFullYear()} Omnicure Inc.`}</span>
    </Typography>
  );
}
const userIdCache = localStorage.getItem("userId");
const appCache = localStorage.getItem("app");
const userTypeCache = localStorage.getItem("userType");
const userDetailsCache = localStorage.getItem("userDetails");
const globalToken = localStorage.getItem("tokenId");
const globalRefreshId = localStorage.getItem("refreshId");
/* const globalEmail = localStorage.getItem("userEmail");
const globalPassword = localStorage.getItem("userPassword"); */
const globalPermission = localStorage.getItem("userPermission");
const userAccess = localStorage.getItem("userAccessId");

const RoutingComponent = ({location,history,userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails}) => {
    if(app && PagesList[app].includes(location.pathname)){
      return <div />
    }
    else if(app && allRoutes.includes(location.pathname)){
      console.log('..one....direct url access...')
      return <Redirect to="/homepage" />
    }
    else if(userId){
      console.log('..two....direct url access...')
      return <Redirect to="/homepage" />
    }
    else if(!userId){
      return <Redirect to="/login" />
    }
    return null
  }

const App = ({ location }) => {
  const [userId, setUserId] = useState(userIdCache === String(null) ? null : userIdCache);
  const [userDetails, setUserDetails] = useState(userDetailsCache === String(null) ? null : JSON.parse(userDetailsCache));
  const [page, setPage] = useState(null);
  const [app, setApp] = useState(appCache === String(null) ? null : appCache);
  const [userType, setUserType] = useState(userTypeCache === String(null) ? null : userTypeCache);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [showSystemAlertModal, setSystemAlertShowModal] = useState(false);
  const [remoteProviderTypeList, setRemoteProviderTypeList] = useState([]);
  const [tokenId, setTokenId] = useState(globalToken === String(null) ? null : globalToken);
  const [refreshId, setRefreshId] = useState(globalRefreshId === String(null) ? null : globalRefreshId);
  /* const [userEmail, setUserEmail] = useState(globalEmail === String(null)? null : globalEmail);
  const [userPassword, setUserPassword] = useState(globalPassword === String(null)? null: globalPassword); */
  const [userPermission, setUserPermission] = useState(globalPermission === String(null)? null : globalPermission);
  const [userAccessId, setUserAccessId] = useState(userAccess === String(null) ? null : userAccess);
  const history = useHistory();
  const [footerAccess] = useState(false);

  const hitSubUserTypeAPI = () => {
    API.get(
      "/userEndpoints/v1/remoteprovidertyperesponse",{
        headers: {  
          'Authorization': tokenId,
      }
      }
    )
      .then((res) => {
        if (res.data.status) {
          setRemoteProviderTypeList(res.data.remoteProviderTypeList);
        }
      })
      .catch(() => {
       // alert("Server did not response please try again...");
      });
  };
  /* useEffect(()=>{
    if(userEmail && userPassword){
    firebase.auth().signInWithEmailAndPassword(userEmail, userPassword)
      .then((res) => {
        console.log('...res..', res)
        console.log('...res..', res.user.za)
        console.log('...refreshToken..', res.user.refreshToken)
        setRefreshId(res.user.refreshToken)
        firebase.auth().currentUser.getIdToken( true).then(function(idToken) {
          // Send token to your backend via HTTPS
          // ...
          setTokenId(idToken)
           
        }).catch((error) => {
          // Handle error
          console.log('firebase expiration', error)
        });
      })
      .catch(() => {});
    }
  },[userEmail,userPassword]); */

  useLayoutEffect(() => {
    // const userId = localStorage.getItem("userId");
    // const app = localStorage.getItem("app");
    // const userType = localStorage.getItem("userType");
    // const userDetails = localStorage.getItem("userDetails");
    // console.log(app, "CheckOne");
    // setUserId(userId === String(null) ? null : userId);
    // setApp(app === String(null) ? null : app);
    // setUserType(userType === String(null) ? null : userType);
    // setUserDetails(
    //   userDetails === String(null) ? null : JSON.parse(userDetails)
    // );
    hitSubUserTypeAPI();
  }, []);

  useEffect(() => {
    console.log('...userAccessId...', userAccessId)
    localStorage.setItem("userId", String(userId));
    localStorage.setItem("app", String(app));
    localStorage.setItem("userType", String(userType));
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    localStorage.setItem("tokenId", String(tokenId));
    localStorage.setItem("refreshId", String(refreshId));
    /* localStorage.setItem("userEmail", String(userEmail));
    localStorage.setItem("userPassword", String(userPassword)); */
    localStorage.setItem("userPermission", String(userPermission));
    console.log(userAccessId,"USERIDACCESS_2")
    localStorage.setItem("userAccessId", String(userAccessId))
    console.log(tokenId,"Token_ID")
  }, [userId, app, userDetails, tokenId, refreshId, userPermission, userAccessId]);

  useEffect(() => {
    setPage(location.pathname);
  }, [location]);



  const theme = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#9B51E0",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#11cb5f",
      },
      success: {
        // This is green.A700 as hex.
        main: "#cb114c",
      },
    },
  });
  const PrivateRoute = ({
    children,
    isCurrentApp,
    userId,
    component,
    location,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        component={
          userId != null && isCurrentApp
            ? component
            : () => (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
        }
      />
    );
  };
  const alertClose = () => {
    setAlertOpen(false);
    setAlertText("");
    setAlertType("error");
  };
  const alert = (text, type) => {
    console.log("...text...", text);
    console.log("...text...", type);
    setAlertText(text);
    type && setAlertType(type);
    setAlertOpen(true);
  };

  const adminPages = [
    {
      name: "Hospital Management",
      route: "/app/hospital",
      icon: <LocalHospitalIcon />,
    },
    {
      name: "Provider Management",
      route: "/app/approvals",
      icon: <PeopleAltIcon />,
    },
    {
      name: "Patient Management",
      route: "/app/patients",
      icon: <AccessibilityIcon />,
    },
    {
      name: "eConsult Record",
      route: "/app/patients/medicalrecord",
      icon: <LocalHospitalIcon />,
    },
    {
      name: "Admin User Management",
      route: "/app/adminusers",
      icon: <AccountCircleIcon />,
    },
    {
      name: "System Alert",
      route: "/app/alert",
      icon: <NotificationImportantIcon />,
    },
    {
      name: "Permission",
      route: "/app/permissions",
      icon: <PolicyIcon />,
    }
  ];
  const triagePages = [
    {
      name: "Patient Management",
      route: "/app/patients",
      icon: <HomeWorkIcon />,
    } /* {
            name: 'Patient Upload',
            route: '/app/patientsupload',
            icon:<AccessibilityIcon/>
        }*/,
  ];

  const dashboardPages = [
    {
      name: "Dashboard",
      route: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      name: "Active Consults",
      route: "/dashboard/active_consults",
      icon: <QuestionAnswerIcon />,
    },
    {
      name: "Pending Consults",
      route: "/dashboard/pending_consults",
      icon: <QuestionAnswerIcon />,
    },
    {
      name: "Wards",
      route: "/dashboard/wards",
      icon: <LocalHospitalIcon />,
    },
    {
      name: "Staffing",
      route: "/dashboard/staffing",
      icon: <SupervisedUserCircleIcon />,
    } /*  {
            name: 'Reports',
            route: '/dashboard/reports',
            icon:<AssessmentIcon/>
        }, */,
  ];
  const renderSwitch = useMemo(
    () => (
      <div className="rootPadding">
        <CustomAlert
          open={alertOpen}
          handleClose={alertClose}
          text={alertText}
          type={alertType}
        />
        <Switch>
          {userId !== null ? (
            <>
              {app === ADMIN_APP ? (
                userType !== "T" ? (
                  <AdminApp
                    app={app}
                    userType={userType}
                    userId={userId}
                    alert={alert}
                    setUserId={setUserId}
                    tokenId={tokenId}
                    setTokenId={setTokenId}
                    refreshId={refreshId}
                    setRefreshId={setRefreshId}
                    remoteProviderTypeList={remoteProviderTypeList}
                    userDetails={userDetails}
                  />
                ) : (
                  <TriageApp 
                  app={app} 
                  userId={userId}
                  setUserId={setUserId}
                  alert={alert} 
                  tokenId={tokenId}
                  setTokenId={setTokenId}
                  refreshId={refreshId}
                  setRefreshId={setRefreshId}/>
                )
              ) : (
                <DashboardApp 
                app={app}
                userId={userId}
                setUserId={setUserId}
                alert={alert} 
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}/>
              )}
            </>
          ) : (
            <>
              <div style={{ fontSize: "22px" }}>
                Please{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "#9B51E0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </a>{" "}
                to access the application
              </div>
            </>
          )}
        </Switch>
      </div>
    ),
    [alertOpen, alertText, alertType, alert, userId, userType, userDetails]
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Switch>
        <Route
          path="/"
          exact
          component={() => (
            <Index
              redirectionURL={
                userId ? "/homepage"
                  : "/login"
              }
            />
          )}
        />
        <Route
          path="/login"
          exact
          render={() => {if(userId){
            return (<Redirect to="/homepage" />)
          }
            return (
            <div style={{ display: "grid", height: "80vh" }}>
              <LoginScreen
                alert={alert}
                userId={userId}
                setUserId={setUserId}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                setUserDetails={setUserDetails}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                /* setUserEmail={setUserEmail}
                setUserPassword={setUserPassword} */
                setUserPermission={setUserPermission}
                userPermission={userPermission}
                userAccessId={userAccessId}
                setUserAccessId={setUserAccessId}
              />
            </div>
          )}}
        />
        <Route path="/app/forgotpassword"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <ForgotPasswordScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}            
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        )} />
        <Route path="/error" exact render={(props)=> <ErrorScreen />} />
        <Route path="/homepage" exact render={(props)=> <NavigationComponent alert={alert}
                userId={userId}
                setUserId={setUserId}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                userPermission={userPermission}
                setUserPermission={setUserPermission}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                userAccessId={userAccessId}
                setUserAccessId={setUserAccessId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                setUserDetails={setUserDetails} {...props} /> } />
              <Route
        path="/app/changePassword"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <ChangePasswordScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}            
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        )}
      />
      <Route
        path="/app/success"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <SuccessScreen setUserId={setUserId} userId={userId} />
        )}
      />
        {app != null && PagesList[app].includes(page) &&  (
          <div>
            <Suspense
              fallback={
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="loader"></div>
                </div>
              }
            >
              <ResponsiveDrawer
                path={location.pathname}
                authUser={userId}
                shouldShowDrawer={page !== LOGIN_SCREEN && app != null}
                setUserDetails={setUserDetails}
                setUserId={setUserId}
                setApp={setApp}
                pages={
                  app === ADMIN_APP
                    ? userType !== "T"
                      ? adminPages
                      : triagePages
                    : dashboardPages
                }
                pageValue={page}
                app={app}
                onPageChange={(value) => {
                  history.push(value);
                }}
                children={renderSwitch}
              />
            </Suspense>
          </div>
        )}
        <Route render={(props)=>{
          
          return (<RoutingComponent alert={alert}
            userId={userId}
            setUserId={setUserId}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
            setUserDetails={setUserDetails} {...props} /> )
        }} />
        </Switch>
        {showSystemAlertModal && (
          <SystemAlertScreen
            handleClose={() => setSystemAlertShowModal(false)}
          />
        )}
        <footer>
          <div className="footerSection">
            <div className="footerBody">
              <Typography variant="body1">
                <Link
                  href="https://www.omnicuremd.com/terms.html"
                  target="_blank"
                  className="footerText"
                  color="primary"
                >
                  Terms & Conditions
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  href="https://www.omnicuremd.com/contact.html"
                  target="_blank"
                  className="footerText"
                  color="primary"
                >
                  Contact Us
                </Link>
              </Typography>

              <Copyright />
            </div>
          </div>
        </footer>
      </div>
    </ThemeProvider>
  );
};

const Index = ({ redirectionURL }) => {
  return <Redirect to={redirectionURL} />;
};

export default withRouter(App);

//##ORIGINAL ROUTING CODE FOR REFERENCE

// <Switch>
//                 <Route path="/" exact component={Index}/>
//                 <Route path="/login" exact
//                        component={() => <LoginScreen alert={alert} userId={userId} setUserId={setUserId} app={app}
//                         setApp={setApp} userType={userType} setUserType={setUserType} setUserDetails={setUserDetails}/>}/>
//                 <Route path="/app/changePassword" isCurrentApp={app === ADMIN_APP && userType !== 'T'} component={() => <ChangePasswordScreen setUserId={setUserId} userId={userId} userDetails={userDetails}/>} />
//                 <Route path="/app/success" isCurrentApp={app === ADMIN_APP && userType !== 'T'} component={() => <SuccessScreen setUserId={setUserId} userId={userId}/>} />

//                 <Route path="/app/forgetPassword" component={PasswordComponent} />

//                 <Route path="/app/sign-up" component={PasswordComponent} />
//                 {userId !==null? <>
//                 <Route  path="/app/hospital" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={HospitalScreen}/>

//                 <Route  path="/app/adminusers" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={AdminUserScreen}/>

//                 <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/approvals" exact
//                               component={() => <ApprovalScreen alert={alert}
//                                                                remoteProviderTypeList={remoteProviderTypeList}/>}/>
//                 <Route  path="/app/patients/medicalrecord" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={MedicalRecordScreen} />

//                 <Route  path="/app/patients" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientScreen}/>

//                 <Route  path="/app/alert" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={SystemAlertScreen}/>

//                 <Route  path="/app/" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}  exact component={PatientUploadScreen} alert={alert}/>

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} path="/app/patientsupload"
//                               exact
//                               componnet={() => <PatientUploadScreen alert={alert}/>}/> */}

//                {/*  <Route  path="/app/patientsupload" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientUploadScreen}/> */}

//                  {/* <Route  path="/app/adminusers" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={AdminUserScreen}/>
//                 <Route  path="/app/approvals" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={ApprovalScreen}/>
//                 <Route  path="/app/patients" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientScreen}/>
//                 <Route  path="/app/alert" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={SystemAlertScreen}/>
//                 <Route  path="/app/patientsupload" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientUploadScreen}/> */}

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/hospital" exact
//                               component={() => <HospitalScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/adminusers" exact
//                               component={() => <AdminUserScreen alert={alert}/>}/> */}

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/approvals" exact
//                               component={() => <ApprovalScreen alert={alert}
//                                                                remoteProviderTypeList={remoteProviderTypeList}/>}/> */}

//                {/*  <PrivateRoute isCurrentApp={app === ADMIN_APP} userId={userId} path="/app/patients" exact
//                               component={() => <PatientScreen alert={alert}/>}/> */}

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} path="/app/alert"
//                               exact
//                               component={() => <SystemAlertScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} path="/app/patientsupload"
//                               exact
//                               component={() => <PatientUploadScreen alert={alert}/>}/> */}

//                 {/*dashboard screens*/}
//                 <Route  path="/dashboard" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={DashboardScreen}/>

//                 <Route  path="/dashboard/active_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ActiveConsultsScreen}/>

//                 <Route  path="/dashboard/pending_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={PendingConsultsScreen}/>

//                 <Route  path="/dashboard/staffing" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={StaffingScreen}/>

//                 <Route  path="/dashboard/wards" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={WardsScreen}/>

//                 <Route  path="/dashboard/reports" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ReportScreen}/>

//                {/*  <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId} path="/dashboard" exact
//                               component={() => <DashboardScreen alert={alert}/>}/> */}
//                 {/* <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId} path="/dashboard/active_consults"
//                               exact
//                               component={() => <ActiveConsultsScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId}
//                               path="/dashboard/pending_consults" exact
//                               component={() => <PendingConsultsScreen alert={alert}/>}/> */}
//                 {/* <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId} path="/dashboard/wards" exact
//                               component={() => <WardsScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId}
//                               path="/dashboard/staffing" exact
//                               component={() => <StaffingScreen alert={alert}/>}/> */}
//                 {/* <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId}
//                               path="/dashboard/reports" exact
//                               component={() => <ReportScreen alert={alert}/>}/> */}
//                 {/* --------------Dashboard Root-------- */}

//                 {/* <Route  path="/dashboard/active_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ActiveConsultsScreen}/>
//                 <Route  path="/dashboard/pending_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={PendingConsultsScreen}/>
//                 <Route  path="/dashboard/wards" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={WardsScreen}/>
//                 <Route  path="/dashboard/staffing" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={StaffingScreen}/>
//                 <Route  path="/dashboard/reports" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ReportScreen}/> */}</>: <><div style={{fontSize:'22px'}}>Please <a style={{textDecoration: 'underline', color:'#9B51E0', cursor:'pointer'}} onClick={() => {
//                     history.push('/login')
//                 }}>Login</a> to access the application</div></>}
//             </Switch>
